<template>
    <p>Discover the right solution for your needs and achieve digital transformation in your business.​</p>
    <div class="d-flex align-items-center gap-3 mb-5">
        <button class="btn primary-btn d-flex gap-2 align-items-center fill">Contact Sales</button>
        <button class="btn primary-btn d-flex gap-2 align-items-center">Get Started</button>
    </div>
    <div class="row solution-card-row">
        <div class="col-6 col-xl-4 mb-3" v-for="(item,i) in items" :key="i">
            <div class="solution-card shadow">
                <h6>{{item.prefix}} <b>{{item.name}}</b></h6>
                <p class=" mt-3">
                    {{ item.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        setup() {
            const items =[
                {
                    prefix: "Firewall",
                    name: "Manager​​",
                    description : "Manage and apply rules for cloud security​.​"
                },
                {
                    prefix: "Web​",
                    name: "Application Firewall​​",
                    description : "Secure your web traffic with cloud WAF​.​​"
                },
                {
                    prefix: "Identity &​",
                    name: "Access Management​",
                    description : "Manage and access cloud resources securely​.​​​"
                },
                {
                    prefix: "Certificate",
                    name: "Manager​​",
                    description : "Generate and attach SSL/TLS certificates​."
                },
                {
                    prefix: "Key",
                    name: "Management Services​",
                    description : "Create and manage encryption keys for secure connections​."
                },
            ];

            return {items}
        }
    }
</script>

<style scoped>

.solutions .solution-card-row {
    margin-top: 40px;
}

.solutions .solution-card {
    padding: 15px 20px;
    border-radius: 10px;
    background: var(--card-background-color);
}

.solutions .solution-card p {
    color: var(--font-mute-dark);
}

</style>
